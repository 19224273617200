<template lang="pug">

div
  v-btn(fixed dark fab bottom right color="orange" @click="showModal = true")
    v-icon add
  v-text-field(clearable prepend-inner-icon="mdi-magnify" label="Search" v-model="search")
  v-list
    v-list-item(v-for="category in filteredCategories" :key="category['.key']")
      v-list-item-content
        v-list-item-title {{category.name}}
      v-list-item-action
        v-btn(text color="orange" @click="navToCategory(category)") Unit Page
  v-dialog(v-model="showModal" max-width="500px")
    v-card
      v-card-actions
        v-spacer
        v-btn(color="orange" text @click="showModal=false") Close
      v-card-text
        form
          v-text-field(v-model="newCategory.name" label="Name" type="text")
          v-text-field(v-model="newCategory.order" label="Order" type="text")
      v-card-actions
        v-btn(color="primary" @click="addCategory()") Add Category
</template>
<script>
import modal from '@/components/modal'
import { mapGetters } from 'vuex'

function initialCategory () {
  return {
    name: '',
    order: ''
  }
}

export default {
  name: 'app',
  data () {
    return {
      showModal: false,
      selected: '',
      search: '',
      newCategory: initialCategory()
    }
  },
  methods: {
    navToCategory (category) {
      this.$router.push({ path: `/categories/${category.id}` })
    },
    resetCategory () {
      this.showModal = false
      this.newFeature = initialCategory()
    },
    addCategory () {
      this.search = this.newCategory.name
      this.$store.dispatch('addCategory', this.newCategory)
      this.resetCategory()
    }
  },
  computed: {
    ...mapGetters([
      'categories'
    ]),
    filteredCategories () {
      var that = this
      var data = this.categories
      return data.filter(category => {
        var searchRegex = new RegExp(that.search, 'i')
        return searchRegex.test(category.name)
      })
    }
  },
  components: {
    modal
  }
}
</script>
<style lang="stylus" scoped>

</style>
